import React, { useEffect, useState } from "react";

const Counter = ({ from, to, interval, suffix }) => {
    const [count, setCount] = useState(from);

    useEffect(() => {
        if (count < to) {
            const timer = setInterval(() => {
                setCount((prevCount) => {
                    const nextCount = prevCount + 1;
                    return nextCount > to ? to : nextCount;
                });
            }, interval);

            return () => clearInterval(timer);
        }
    }, [count, to, interval]);

    return (
        <span className="pbmit-number-rotate numinate">{count}{suffix}</span>
    );
};

function CounterPage() {
    const counters = [
        { from: 0, to: 650, interval: 10, suffix: "m", subtext: "Distance covered daily by Glorious EV users." },
        { from: 0, to: 564, interval: 10, suffix: "+", subtext: "Charging stations available nationwide." },
        { from: 0, to: 125, interval: 10, suffix: "k", subtext: "Satisfied riders using Glorious EV." },
    ];

    return (
        <section>
            <div className="container">
                <div className="row">
                    {counters.map((counter, index) => (
                        <div className="col-md-4" key={index}>
                            <div className="pbminfotech-ele-fid-style-2">
                                <div className="pbmit-fld-contents">
                                    <div className="pbmit-sbox-icon-wrapper">
                                        <i className="pbmit-grevo-icon pbmit-grevo-icon-jockey-1"></i>
                                    </div>
                                    <div className="pbmit-fld-wrap">
                                        <h4 className="pbmit-fid-inner">
                                            <Counter from={counter.from} to={counter.to} interval={counter.interval} />
                                            <span className="pbmit-fid"><sup>{counter.suffix}</sup></span>
                                        </h4>
                                        <div className="pbmit-fid-title">
                                            <span>{counter.subtext}</span> {/* Display the subtext here */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default CounterPage;
