import React from 'react'
import Button from './Button'
import LocalGasStationOutlinedIcon from '@mui/icons-material/LocalGasStationOutlined';
function Price2() {
    return (
        <>
            <div className="col-sm-12 col-md-6 col-xl-4">
            <div className="pbmit-pricing-table-featured-col pbmit-ptable-col">
                <div className="pbmit-pricing-table-box">
                    <div className="pbmit-ptablebox-featured-w">Featured</div>
                    <div className="pbmit-icon-wrap">
                        <div className="pbmit-ptable-icon">
                            <div className="pbmit-ptable-icon-wrapper">
                                <LocalGasStationOutlinedIcon className="pbmit-grevo-icon" />
                            </div>
                        </div>
                    </div>
                    <div className="pbmit-head-wrap">
                        <h3 className="pbminfotech-ptable-heading">480 Volt Station</h3>
                        <div className="pbminfotech-sep" />
                    </div>
                    {/* <div className="pbminfotech-ptable-price-w">
                        <div className="pbminfotech-ptable-price">200</div>
                        <div className="pbminfotech-ptable-symbol">$</div>
                        <div className="pbminfotech-ptable-frequency">mo</div>
                    </div> */}
                    <div className="pbmit-ptable-inner">
                        <div className="pbmit-ptable-lines-w">
                            <div className="pbmit-ptable-line">
                                <i className="fa fa-check-circle" /> Ultra-fast charging for minimal downtime
                            </div>
                            <div className="pbmit-ptable-line">
                                <i className="fa fa-check-circle" /> Enhanced safety features for worry-free charging
                            </div>
                            <div className="pbmit-ptable-line">
                                <i className="fa fa-check-circle" /> Compatible with multiple models
                            </div>
                            <div className="pbmit-ptable-line">
                                <i className="fa fa-check-circle" /> Real-time charging status updates via mobile app
                            </div>
                        </div>
                        {/* <div className="pbmit-ptable-btn">
                            <a href="our-plans.html"><Button name='View More' color="#fff" /></a>
                        </div> */}
                    </div>
                </div>
            </div>
            </div>
        </>
    )
}

export default Price2
