import BlogImg1 from '../../images/blog/blog-01.jpg';
import BlogImg2 from '../../images/blog/blog-02.jpg';
import BlogImg3 from '../../images/blog/blog-03.jpg';

export const Data = [
    {
        title: "The Future of Electric Mobility",
        imgURL: BlogImg1,
        date: "03 JUN 2020",
        authorName: "Dillep",
        comment: "Comment",
        commentCount: 2
    },
    {
        title: "Top 5 Benefits of Switching to Electric Vehicles",
        imgURL: BlogImg2,
        date: "13 JUN 2020",
        authorName: "Lakshmi",
        comment: "Comment",
        commentCount: 5
    },
    {
        title: "A Guide to Charging Your EV at Home and on the Go",
        imgURL: BlogImg3,
        date: "23 JUN 2020",
        authorName: "Karan",
        comment: "Comment",
        commentCount: 10
    }
]
