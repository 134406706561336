import React from 'react'
import { BrowserRouter as Router, Link } from "react-router-dom";
import Breadcrumb from '../common/Breadcrumb'
import ContactImg from '../../images/img-07.png'
import Iframe from 'react-iframe'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';

import Button from '../common/Button'
function ContactUs() {
    return (
        <>
            <Breadcrumb title="Contact Us" compnayname="GloriousEV" pagename="Contact Us" />


            <section className="section-lg contact-section">
                <div className="container">
                    <div className="pbmit-heading-subheading center-align text-center">
                        <h4 className="pbmit-subtitle">GET IN TOUCH</h4>
                        <h2 className="pbmit-title">We’d Love To Help You</h2>
                        <div className="pbmit-heading-desc">Please feel free to get in touch using the form below. We’d love to hear your thoughts answer any questions you may have!
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6  col-sm-12">
                            <div className="pbmit-ihbox pbmit-ihbox-style-10">
                                <div className="pbmit-ihbox-box d-flex">
                                    <div className="pbmit-ihbox-icon">
                                        <div className="pbmit-ihbox-icon-wrapper">
                                            <i className="pbmit-grevo-icon pbmit-grevo-icon-real-estate" />
                                        </div>
                                    </div>
                                    <div className="pbmit-ihbox-contents">
                                        <h2 className="pbmit-element-title">Our Location​​</h2>
                                        <div className="pbmit-heading-desc">Ranchi, Dhoni, Form House <br /> Opposite Jharkhand
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6  col-sm-12">
                            <div className="pbmit-ihbox pbmit-ihbox-style-10">
                                <div className="pbmit-ihbox-box d-flex">
                                    <div className="pbmit-ihbox-icon">
                                        <div className="pbmit-ihbox-icon-wrapper">
                                            <i className="pbmit-grevo-icon pbmit-grevo-icon-time-call" />
                                        </div>
                                    </div>
                                    <div className="pbmit-ihbox-contents">
                                        <h2 className="pbmit-element-title">Support</h2>
                                        <div className="pbmit-heading-desc">For Any Query <br /><Link to='#'>Support@gloriousev.com</Link></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6  col-sm-12 mt-md-4 mt-lg-0 ">
                            <div className="pbmit-ihbox pbmit-ihbox-style-10">
                                <div className="pbmit-ihbox-box d-flex">
                                    <div className="pbmit-ihbox-icon">
                                        <div className="pbmit-ihbox-icon-wrapper">
                                            <i className="pbmit-grevo-icon pbmit-grevo-icon-open" />
                                        </div>
                                    </div>
                                    <div className="pbmit-ihbox-contents">
                                        <h2 className="pbmit-element-title">Email Address</h2>
                                        <div className="pbmit-heading-desc">For Any Query <br /><Link to='#'>info@gloriousev.com</Link></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="text-center pt-5">
                        <div className="pbmit-text-style-1">By continuing, you agree to our terms and privacy policy.
                            <Link className="text-btn" to="#">Accept</Link>
                        </div>
                    </div> */}
                </div>
            </section>

            <section className="contact-section-bg">
                <div className="container-fluid ">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12 pbmit-bg-color-global">
                            <div className="text-left">
                                <div className="pbmit-heading-subheading text-left">
                                    <h4 className="pbmit-subtitle">Get in touch </h4>
                                    <h2 className="pbmit-title text-white">Send Message Us</h2>
                                    <p className="text-white">Just send us your questions or concerns to <br /> starting a new project.</p>
                                </div>
                                <div className="pbmit-ihbox pbmit-ihbox-style-11">
                                    <div className="pbmit-ihbox-box d-flex align-items-center mb-4">
                                        <div className="pbmit-ihbox-icon">
                                            <div className="pbmit-ihbox-icon-wrapper pbmit-ihbox-icon-type-image">
                                                <img src={ContactImg} alt="Have a Question?" />
                                            </div>
                                        </div>
                                        <div className="pbmit-ihbox-contents">
                                            <h2 className="pbmit-element-title"> Have a Question?</h2>
                                            <h4 className="pbmit-element-heading text-white"><Link to='#'>Support@gloriousev.com</Link></h4>
                                        </div>
                                    </div>
                                    <div className="widget-container text-white">
                                        Monday – Friday 9.00 – 6.00<br />
                                        Sunday &amp; Public Holidays (Closed)
                                    </div>
                                    <div className="button-wrapper">
                                        <Link to="#" className="button-link">
                                            <span className="button-content-wrapper">
                                                <span className="button-text">Request a call back <FontAwesomeIcon icon={faCaretRight} /> </span>
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 pbmit-bg-color-light">
                            <form className="contact-form">
                                <div className="row mb-3 g-3">
                                    <div className="col-lg-6 col-md-6">
                                        <input type="text" className="form-control" placeholder="First Name" name="First Name" />
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <input type="text" className="form-control" placeholder="Last Name" name="Last Name" />
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <input type="text" className="form-control" placeholder="Email Address" />
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <input type="text" className="form-control" placeholder="Phone" name="Phone" />
                                    </div>
                                    <div className="col-lg-12 col-sm-12">
                                        <textarea className="form-control" name="message" rows={4} placeholder="Message Write Here" defaultValue={""} />
                                    </div>
                                    <div className="col-lg-12 col-sm-12">
                                        <Link to="#" className="pbmit-btn pbmit-btn-secondary pbmit-btn-hover-global">
                                            <Button name="Send message" />
                                        </Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>



        </>
    )
}

export default ContactUs
