import GalleryImg1 from '../../images/project/Gallery-01.jpg';
import GalleryImg2 from '../../images/project/Gallery-02.jpg';
import GalleryImg3 from '../../images/project/Gallery-03.jpg';
import GalleryImg4 from '../../images/project/Gallery-04.jpg';
import GalleryImg5 from '../../images/project/Gallery-05.jpg';
import GalleryImg6 from '../../images/project/Gallery-06.jpg';

export const GalleryData = [
    {
        title: "Glorious EV Gallery",
        imgURL: GalleryImg1,
        subtitle: "Glorious EV",
    },
    {
        title: "Glorious EV Gallery",
        imgURL: GalleryImg2,
        subtitle: "Glorious EV"
    },
    {
        title: "Glorious EV Gallery",
        imgURL: GalleryImg3,
        subtitle: "Glorious EV"
    },
    {
        title: "Glorious EV Gallery",
        imgURL: GalleryImg4,
        subtitle: "Glorious EV"
    },
    {
        title: "Glorious EV Gallery",
        imgURL: GalleryImg5,
        subtitle: "Glorious EV"
    },
    {
        title: "Glorious EV Gallery",
        imgURL: GalleryImg6,
        subtitle: "Glorious EV"
    }
]
