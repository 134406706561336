import React from 'react'
import Breadcrumb from '../common/Breadcrumb'
import ServiceCard from '../common/ServiceCard'
import { ServiceData } from '../Data/ServiceData'
function Services() {
    return (
        <>
            <Breadcrumb title="Our Services" compnayname="GloriousEV" pagename="Services" />
            <section className="section-lgx">
                <div className="container">
                    <div className="row">
                        {ServiceData.map((itm) => <ServiceCard
                            imgurl={itm.imgURL}
                            title={itm.title}
                            point1={itm.point1}
                            point2={itm.point2}
                            itemHide={true}
                        />)}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Services
