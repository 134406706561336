import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Link } from "react-router-dom";
import logoWhite from '../images/GloriousEVWhite.png';
import gravoLogo from '../images/GloriousEV.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faPhone, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useSpring } from 'react-spring';
import Button from './common/Button';

export default function Navbar() {
    const [isSticky, setIsSticky] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [open, setopen] = useState(null);

    const handleScroll = () => {
        const scrollY = window.scrollY;
        if (scrollY > 5) {
            setIsSticky(true);
        } else {
            setIsSticky(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const toggleSubMenu = (index) => {
        if (open === index) {
            setopen(null);
        } else {
            setopen(index);
        }
    };

    const closeMobileMenuOnClick = () => {
        setIsMobileMenuOpen(false); // This will close the mobile menu
    };

    return (
        <>
            <header className="site-header header-style-2">
                <div className="pbmit-header-overlay">
                    <div className={`site-header-menu ${isSticky ? 'sticky-header' : ''}`}>
                        <div className="pbmit-header-bg">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="pbmit-logo-menuarea d-flex align-items-center">
                                            <div className="site-branding">
                                                <Link to="/">
                                                    <img className="logo-img" alt="Grevo" src={logoWhite} />
                                                    <img className="pbmit-sticky-logo" src={gravoLogo} alt="Grevo Demo2" title="Grevo Demo2" />
                                                </Link>
                                            </div>
                                            <div className="site-navigation">
                                                <nav className="main-menu navbar-expand-xl navbar-light">
                                                    <div className="navbar-header">
                                                        {/* Toggle Button */}
                                                        <button className="navbar-toggler" type="button" onClick={toggleMobileMenu}>
                                                            <FontAwesomeIcon icon={isMobileMenuOpen ? faTimes : faBars} />
                                                        </button>
                                                    </div>
                                                    <div className={`collapse navbar-collapse ${isMobileMenuOpen ? 'show' : ''}`} id="navbarSupportedContent">
                                                        <ul className="navigation clearfix">
                                                            <li>
                                                                <Link to="/" onClick={closeMobileMenuOnClick}>
                                                                    Home
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/AboutUsPage" onClick={closeMobileMenuOnClick}>
                                                                    About Us
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/Services" onClick={closeMobileMenuOnClick}>
                                                                    Services
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/Features" onClick={closeMobileMenuOnClick}>
                                                                    Features
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/ContactUs" onClick={closeMobileMenuOnClick}>
                                                                    Contact Us
                                                                </Link>
                                                            </li>
                                                            <li className="desk-none">
                                                                <Link to="https://gloriousev.com/member-registration.aspx" target="_blank" onClick={closeMobileMenuOnClick}>
                                                                    Sign Up
                                                                </Link>
                                                            </li>
                                                            <li className="desk-none">
                                                                <Link to="https://gloriousev.com/memberpanel/login.aspx" target="_blank" onClick={closeMobileMenuOnClick}>
                                                                    Login
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                        <div className="pbmit-header-search-btn">
                                                            <Link to="#"><i className="pbmit-base-icon-search-1" /></Link>
                                                        </div>
                                                    </div>
                                                </nav>
                                            </div>
                                        </div>
                                        <div className="pbmit-button-box">
                                            <div className="pbmit-header-button">
                                                <Link to="https://gloriousev.com/member-registration.aspx" target="_blank" className="pbmit-btn pbmit-btn-outline blog-btn-outline">
                                                    <Button name='Sign Up' />
                                                </Link>
                                            </div>
                                            <div className="header-button">
                                                <Link to="https://gloriousev.com/memberpanel/login.aspx" target="_blank" className="pbmit-btn pbmit-btn-secondary pbmit-btn-hover-global">
                                                    <Button name='Login' />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}
