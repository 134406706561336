import React from 'react'
import whatwedo from '../../images/main.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChargingStation } from '@fortawesome/free-solid-svg-icons';
import { faBolt } from '@fortawesome/free-solid-svg-icons';
import { faCarSide } from '@fortawesome/free-solid-svg-icons';
function WhatWeDo(props) {
    return (
        <>
            {/* What we do Start */}
            <section className={`home2-section-what-we-do ${props.customClass}`}>
                <div className="container">
                    <div className="pbmit-heading-subheading center-align text-center">
                        <h4 className="pbmit-subtitle">Glorious EV</h4>
                        <h2 className="pbmit-title">Why Choose a Glorious EV E-Scooter?</h2>
                    </div>
                    <div className="row g-5 align-items-end">
                        <div className="col-md-6 col-xl-4 m-0">
                            <div className="pbmit-ihbox pbmit-ihbox-style-5">
                                <div className="pbmit-ihbox-box d-flex flex-row-reverse align-items-center">
                                    <div className="pbmit-ihbox-icon">
                                        <div className="pbmit-ihbox-icon-wrapper">
                                            <FontAwesomeIcon icon={faChargingStation} className="pbmit-grevo-icon" />
                                        </div>
                                    </div>
                                    <div className="pbmit-ihbox-contents">
                                        <h2 className="pbmit-element-title">Energy Regeneration</h2>
                                        <div className="pbmit-heading-desc">Recover energy while braking, extending battery life for more mileage.</div>
                                    </div>
                                </div>
                            </div>
                            <div className="pbmit-ihbox pbmit-ihbox-style-5">
                                <div className="pbmit-ihbox-box d-flex flex-row-reverse align-items-center">
                                    <div className="pbmit-ihbox-icon">
                                        <div className="pbmit-ihbox-icon-wrapper">
                                        <FontAwesomeIcon icon={faBolt} className="pbmit-grevo-icon" />
                                        </div>
                                    </div>
                                    <div className="pbmit-ihbox-contents">
                                        <h2 className="pbmit-element-title">Fast Charging Technology</h2>
                                        <div className="pbmit-heading-desc">Get back on the road quickly with fast-charging capabilities.</div>
                                    </div>
                                </div>
                            </div>
                            <div className="pbmit-ihbox pbmit-ihbox-style-5">
                                <div className="pbmit-ihbox-box d-flex flex-row-reverse align-items-center">
                                    <div className="pbmit-ihbox-icon">
                                        <div className="pbmit-ihbox-icon-wrapper">
                                        <FontAwesomeIcon icon={faCarSide} className="pbmit-grevo-icon"  />
                                        </div>
                                    </div>
                                    <div className="pbmit-ihbox-contents">
                                        <h2 className="pbmit-element-title">Long-Lasting Battery</h2>
                                        <div className="pbmit-heading-desc">Travel up to 100 km on a single charge with our durable batteries.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-4 home2-section-what-we-do-img">
                            <img src={whatwedo} alt />
                        </div>
                        <div className="col-md-6 col-xl-4 m-0">
                            <div className="pbmit-ihbox pbmit-ihbox-style-6">
                                <div className="pbmit-ihbox-box d-flex align-items-center">
                                    <div className="pbmit-ihbox-icon">
                                        <div className="pbmit-ihbox-icon-wrapper">
                                        <FontAwesomeIcon icon={faChargingStation} className="pbmit-grevo-icon" />
                                        </div>
                                    </div>
                                    <div className="pbmit-ihbox-contents">
                                        <h2 className="pbmit-element-title">Eco-Friendly Travel </h2>
                                        <div className="pbmit-heading-desc">Reduce carbon emissions without sacrificing performance.</div>
                                    </div>
                                </div>
                            </div>
                            <div className="pbmit-ihbox pbmit-ihbox-style-6">
                                <div className="pbmit-ihbox-box d-flex align-items-center">
                                    <div className="pbmit-ihbox-icon">
                                        <div className="pbmit-ihbox-icon-wrapper">
                                        <FontAwesomeIcon icon={faBolt} className="pbmit-grevo-icon" />
                                        </div>
                                    </div>
                                    <div className="pbmit-ihbox-contents">
                                        <h2 className="pbmit-element-title">Smart Connectivity</h2>
                                        <div className="pbmit-heading-desc">Monitor your scooter via our smart app for real-time updates.</div>
                                    </div>
                                </div>
                            </div>
                            <div className="pbmit-ihbox pbmit-ihbox-style-6">
                                <div className="pbmit-ihbox-box d-flex align-items-center">
                                    <div className="pbmit-ihbox-icon">
                                        <div className="pbmit-ihbox-icon-wrapper">
                                        <FontAwesomeIcon icon={faCarSide} className="pbmit-grevo-icon"  />
                                        </div>
                                    </div>
                                    <div className="pbmit-ihbox-contents">
                                        <h2 className="pbmit-element-title"> Silent and Smooth Ride</h2>
                                        <div className="pbmit-heading-desc">Enjoy a quiet, smooth ride with noise reduction technology.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* What we do End */}

        </>
    )
}

export default WhatWeDo
