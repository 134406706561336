import React, { Children } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';

function Button(props) {
    //const {Children} = props;
    return (
        <>
            <span>{props.name}<FontAwesomeIcon icon={faCaretRight} /></span>
        </>
    )
}

export default Button

