import React from 'react'
import ServiceCard from '../common/ServiceCard'
import { ServiceData } from '../Data/ServiceData';
import Button from '../common/Button';
function ServicesSec() {
  return (
    <section className="section-lgx">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6 pbmit-heading-subheading text-left">
                        <h4 className="pbmit-subtitle">Glorious EV</h4>
                        <h2 className="pbmit-title">Our Services</h2>
                        <p>At Glorious EV, we offer more than just high-quality electric vehicles. Our services are designed to enhance your ownership experience:</p>
                    </div>
                    <div className="col-md-6 text-md-end mb-5 mb-md-0">
                        <a href="#" className="pbmit-btn pbmit-btn-outline blog-btn-outline">
                           <Button name='View More Services' /> 
                        </a>
                    </div>
                </div>
                <div className="row">
                    {ServiceData.map((itm) => <ServiceCard
                        imgurl={itm.imgURL}
                        title={itm.title}
                        point1={itm.point1}
                        point2={itm.point2}
                    />)}
                </div>
            </div>
        </section>
  )
}

export default ServicesSec
