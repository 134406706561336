import React from 'react'
import aboutImg1 from '../../images/img-n-01.jpg';
import aboutImg2 from '../../images/img-n-02.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import { faIdCard } from '@fortawesome/free-regular-svg-icons';
import { faCarSide } from '@fortawesome/free-solid-svg-icons';
import Button from '../common/Button';
function AboutUs(props) {
  return (
    <>
      {/* Welcome */}
      <section className="section-lg">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="pbminfotech-ele-fid-style-4 home-2-section-about-left">
                <div className="pbmit-fld-contents">
                  <div className="pbmit-sbox-icon-wrapper">
                    <i className="fas fa-star" />
                  </div>
                  <div className="pbmit-fld-wrap">
                    <h4 className="pbmit-fid-inner">
                      <span className="pbmit-number-rotate numinate" data-appear-animation="animateDigits" data-from={0} data-to={85} data-interval={5} data-before data-before-style data-after data-after-style>85</span><span className="pbmit-fid"><sup>%</sup></span>
                    </h4>
                    <div className="pbmit-fid-title"><span>Clients</span></div>
                  </div>
                </div>
              </div>
              <img src={aboutImg1} className="img-fluid about-img-01" alt />
              <img src={aboutImg2} className="img-fluid about-img-02" alt />
            </div>
            <div className="col-md-6 home-2-section-about-right">
              <div className="pbmit-heading-subheading">
                <h4 className="pbmit-subtitle">About Us</h4>
                <h2 className="pbmit-title">Welcome to Glorious EV</h2>
                <div className="pbmit-heading-desc pe-5">Glorious EV, your one-stop destination for eco-friendly, energy-efficient transportation solutions. As a leader in the electric vehicle industry, we are committed to revolutionizing how people travel by offering a comprehensive range of electric bikes, scooters, and cars. Our mission is to provide sustainable, high-performance alternatives to traditional vehicles, ensuring a greener, cleaner future for everyone.</div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-xl-6">
                  <div className="pbmit-ihbox pbmit-ihbox-style-4">
                    <div className="pbmit-ihbox-box mb-4">
                      <div className="pbmit-ihbox-headingicon d-flex align-items-center">
                        <div className="pbmit-ihbox-icon">
                          <div className="pbmit-ihbox-icon-wrapper">
                            <FontAwesomeIcon icon={faCarSide} size={40} color='#8cc63f' />
                          </div>
                        </div>
                        <h2 className="pbmit-element-title"> Zero contact travel </h2>
                      </div>
                      <div className="pbmit-ihbox-contents">
                        <div className="pbmit-heading-desc">Enjoy safe, touch-free charging and riding for a seamless journey.</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-xl-6">
                  <div className="pbmit-ihbox pbmit-ihbox-style-4">
                    <div className="pbmit-ihbox-box">
                      <div className="pbmit-ihbox-headingicon d-flex align-items-center">
                        <div className="pbmit-ihbox-icon">
                          <div className="pbmit-ihbox-icon-wrapper">
                            <FontAwesomeIcon icon={faIdCard} size={40} color='#8cc63f' />
                          </div>
                        </div>
                        <h2 className="pbmit-element-title"> No Driving License </h2>
                      </div>
                      <div className="pbmit-ihbox-contents">
                        <div className="pbmit-heading-desc">Ride freely without the hassle of a license!</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <ul className="list-group list-group-borderless mt-2">
                <li className="list-group-item">
                  <FontAwesomeIcon icon={faCircleCheck} fill='#8cc63f' /> Detachable battery, take home and charge in 3 hours.
                </li>
                <li className="list-group-item">
                  <FontAwesomeIcon icon={faCircleCheck} /> Enjoy a hassle-free ride by charging just for 3 hours.
                </li>
              </ul> */}
              {!props?.buttonHide ? <a href="#" className="pbmit-btn pbmit-btn-secondary pbmit-btn-hover-global">
                <Button name="Read More" />
              </a> : ''}
            </div>
          </div>
        </div>
      </section>
      {/* Welcome end */}

    </>
  )
}

export default AboutUs
