import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';

function VideoSec() {
    return (
        <>
            {/* Video Start */}
            <section className="section-lgs">
                <div className="container">
                    <div className="home2-section-about-bg">
                        <div className="button-wrapper">
                            <a href="https://www.youtube.com/watch?v=3SAxXUIre28" target="_blank" className="button-link pbmin-lightbox-video" role="button">
                                <span className="button-content-wrapper">
                                    <span className="button-icon align-icon-left">
                                    <FontAwesomeIcon icon={faYoutube} />
                                    </span>
                                    <span className="button-text">Watch Our Video</span>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            {/* Video End */}

        </>
    )
}

export default VideoSec
