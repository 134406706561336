import React from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
function GalleryCard(props) {
    const { imgurl, subtitle, title} = props

        return (
            <>
                <div className="col-sm-12 col-md-4">
                    <article className="pbmit-portfolio-style-3">
                        <div className="pbminfotech-post-item">
                            <div className="pbmit-image-wrapper">
                                <div className="pbmit-featured-wrapper">
                                    <img src={imgurl} className="img-fluid" alt />
                                </div>
                            </div>
                            <div className="pbminfotech-content">
                                <div className="pbmit-content-wrapper">
                                    <div className="pbmit-port-cat"><a href="#" rel="tag">{subtitle}</a></div>
                                    <h3 className="pbmit-portfolio-title">
                                        <a href="#">{title}</a>
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </>
        )
    }

    export default GalleryCard;
