import React from 'react';
import BlogSec from '../common/BlogSec';
import { Data } from '../Data/AllData';
import Button from '../common/Button';

function Blog() {
    return (
        <section className="section-lgx">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6 pbmit-heading-subheading text-left">
                        <h4 className="pbmit-subtitle">FRESH NEWS</h4>
                        <h2 className="pbmit-title">Our recent article for the electric vehicle systems</h2>
                    </div>
                    <div className="col-md-6 text-md-end mb-5 mb-md-0">
                        <a href="blog-grid.html" className="pbmit-btn pbmit-btn-outline blog-btn-outline">
                           <Button name='View More Blog' /> 
                        </a>
                    </div>
                </div>
                <div className="row">
                    {Data.map((itm) => <BlogSec
                        imgurl={itm.imgURL}
                        title={itm.title}
                        date={itm.date}
                        authorName={itm.authorName}
                        comment={itm.comment}
                        commentCount={itm.commentCount}
                    />)}
                </div>
            </div>
        </section>

    )
}

export default Blog
