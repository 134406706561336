import React from 'react'
import Breadcrumb from '../common/Breadcrumb'
import AboutUs from '../mai_content/AboutUsSec'
import PriceSec from '../mai_content/PriceSec'
import CounterPage from '../mai_content/Counter'
import WhatWeDo from '../mai_content/WhatWeDo'

function AboutUsPage() {
    return (
        <>
            <Breadcrumb title="About Us" compnayname="GloriousEV" pagename="About Us" />
            <AboutUs buttonHide={true} />
            <CounterPage />
            <WhatWeDo customClass="Padingtop" />
            <PriceSec />
        </>
    )
}

export default AboutUsPage
