import React from 'react'
import Breadcrumb from '../common/Breadcrumb'
import ProductFeature from '../mai_content/ProductFeature'
import VideoSec from '../mai_content/VideoSec'
import BookingSection from '../common/BookingSection'

function Features() {
  return (
    <>
        <Breadcrumb title="Our Products & Features" compnayname="GloriousEV" pagename="Features" />
        <ProductFeature / >
        <BookingSection />
        <VideoSec />
    </>
  )
}

export default Features
