import React from 'react';
import PersonIcon from '@mui/icons-material/Person';
import ForumIcon from '@mui/icons-material/Forum';
function BlogSec(props) {
    const { imgurl, title, date, authorName, comment, commentCount } = props

    return (
        <div className="col-md-4">
            <article className="pbmit-blog-style-2">
                <div className="post-item">
                    <div className="pbmit-featured-container">
                        <div className="pbmit-featured-wrapper">
                            <img src={imgurl} className="img-fluid" alt />
                        </div>
                        <div className="pbmit-meta-date-wrapper">
                            <span>{date}</span>
                        </div>
                    </div>
                    <div className="pbminfotech-box-content">
                        <div className="pbminfotech-content-wrapper">
                            <div className="pbmit-meta-container">
                                <div className="pbmit-meta-author-wrapper pbmit-meta-line">
                                    <a href="#" title="Posted by admin" rel="author">
                                        <PersonIcon /> {authorName}
                                    </a>
                                </div>
                                <div className="pbmit-meta-comment-wrapper pbmit-meta-line">
                                    <span className="pbmit-meta pbmit-meta-comments">
                                        <ForumIcon className='comment' /> {commentCount}
                                        <span className="pbmit-meta pbmit-meta-comments">{comment}</span>
                                    </span>
                                </div>
                            </div>
                            <h3 className="pbmit-post-title">
                                <a href="blog-details.html">{title}</a>
                            </h3>
                        </div>
                    </div>
                </div>
            </article>
        </div>


    )
}

export default BlogSec
