import React from 'react'
import Price1 from '../common/Price1'
import Price2 from '../common/Price2'
import priceSecImg from '../../images/img-n-05.png';
function PriceSec() {
    return (
        <>
            {/* Pricing Start */}
            <section className="section-lgb text-center">
                <div className=" pbmit-bg-color-light border-radius home-2-section-pricing">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 col-xl-4">
                                <div className="pbmit-heading-subheading">
                                    <h4 className="pbmit-subtitle">What we do!</h4>
                                    <h2 className="pbmit-title pe-2">Powering Your Electric Journey</h2>
                                    <div className="pbmit-heading-desc">Empower your journey with Glorious EV’s advanced charging solutions, whether at home or on the go. Enjoy reliable charging access anytime, anywhere, with our growing network of smart charging stations.</div>
                                </div>
                                <img src={priceSecImg} className="img-fluid" alt />
                            </div>

                            <Price1 />

                            <Price2 />

                        </div>
                    </div>
                </div>
            </section>
            {/* Pricing End */}

        </>
    )
}

export default PriceSec

