import Product1 from '../../images/services/e-scooter.jpg'
import Product2 from '../../images/services/e-bike.jpg'
import Product3 from '../../images/services/e-car.jpg'
export const ProductData = [
   
    {
        title: "E-Scooters",
        imgURL: Product1,
        para: "Our E-scooters are built to make short-distance travel fun, fast, and eco-friendly.",
        point1: "High-Performance Motor", 
        point2: "Recharge in Minutes", 
        point3: "Compact and Portable" 
    },
    {
        title: "E-Bikes",
        imgURL: Product2,
        para: "Experience the perfect blend of technology and comfort with our electric bikes.",
        point1: "Long Battery Life", 
        point2: "Lightweight Design", 
        point3: "Advanced Safety Features" 
    },
    {
        title: "E-Cars",
        imgURL: Product3,
        para: "Drive into the future with our range of electric cars that combine luxury, performance, and sustainability.",
        point1: "Zero Emissions", 
        point2: "Smart Tech Integration", 
        point3: "Comfort & Space" 
    }
]