import React from 'react';
import { motion } from 'framer-motion';
import transparentImg from '../revolution/images/transparent.png';
import slider1 from '../revolution/images/slider-img-01.png';
import round2Img from '../revolution/images/round-02.png';
import { IoSpeedometerOutline } from "react-icons/io5";
import { TfiTimer } from "react-icons/tfi";
import { GoVerified } from "react-icons/go";
import Button from './common/Button';


const MainSlider = () => {
  return (
<>
<p className="rs-p-wp-fix"> </p>

    <div className="mainBG ">
      <span className="pbmit-social-label">
      </span>
      <ul className="pbmit-social-links">
        <li className="pbmit-social-li pbmit-social-facebook">
          <a href="#" target="_blank">
            <span>
              <i className="pbmit-base-icon-facebook-squared"></i>
            </span>
          </a>
        </li>
        <li className="pbmit-social-li pbmit-social-twitter">
          <a href="#" target="_blank">
            <span>
              <i className="pbmit-base-icon-twitter"></i>
            </span>
          </a>
        </li>
        <li className="pbmit-social-li pbmit-social-youtube">
          <a href="#" target="_blank">
            <span>
              <i className="pbmit-base-icon-youtube-play"></i>
            </span>
          </a>
        </li>
        <li className="pbmit-social-li pbmit-social-linkedin">
          <a href="#" target="_blank">
            <span>
              <i className="pbmit-base-icon-linkedin-squared"></i>
            </span>
          </a>
        </li>
      </ul>

      {/* Content Container */}
      <div className="bgColor">
        <div className="container secDiv">
          {/* Animated Text Section */}
          <motion.div
            initial={{ x: '100%' }}
            animate={{ x: 0 }}
            transition={{ duration: 1.5, ease: 'easeOut' }}
            className="textDiv"
          >
            <h2 className="mainText">
            Purchase Your <br />
              <span style={{ color: '#000000' }}>E-Vehicle</span> With <br />  <span style={{ color: '#000000' }}>Glorious EV </span>
            </h2>
            <div className="SubText">
              <div style={{ textAlign: 'left' }}>
                <IoSpeedometerOutline className="homeIcon" />
                <div className="subTopText">80 km/h</div>
                <div className="subBottomText">Top speed</div>
              </div>
              <div style={{ textAlign: 'left' }}>
                <TfiTimer className="homeIcon" />
                <div className="subTopText">0-40 km/hr in</div>
                <div className="subBottomText">3.3 sec</div>
              </div>
              <div style={{ textAlign: 'left' }}>
                <GoVerified className="homeIcon" />
                <div className="subTopText">Certified Range</div>
                <div className="subBottomText">116 km</div>
              </div>
            </div>
            <a href="contact-us.html" className="pbmit-btn pbmit-btn-secondary pbmit-btn-hover-global">
              <Button name='Book Now' />
            </a>
          </motion.div>

          {/* Animated Image Section */}
          <motion.div
            initial={{ y: '-80%' }}
            animate={{ y: 0 }}
            transition={{ duration: 1.5, ease: 'easeOut' }}
          >
            <img src={round2Img} alt="round-02.png" className="circleIMG" />
            <img src={slider1} alt="slider-img-01.png" className="mainIMG" />
          </motion.div>
        </div>
      </div>
    </div>
    </>
  );
};

export default MainSlider;
