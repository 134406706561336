import React from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
function ProductCard(props) {
    const { imgurl, title, para, point1, point2, point3 } = props

    return (
        <div className="col-sm-12 col-md-4">
            <article className="pbmit-service-style-1 ev-charging">
                <div className="pbminfotech-post-item">
                    <div className="pbmit-service-img-wrapper">
                        <div className="pbmit-featured-wrapper">
                            <img src={imgurl} className="img-fluid" alt />
                        </div>
                        <div className="pbminfotechi-box-overlay" />
                    </div>
                    <div className="pbminfotech-box-content">
                        <div className="pbminfotech-box-content-inner">
                            <h3 className="pbmit-service-title"><a href="service-details.html">{title}</a></h3>
                            <div className="pbmit-service-content">
                                <p>{para}</p>
                            </div>
                            <ul className="list-group list-group-borderless mt-2">
                                <li className="list-group-item">
                                    <CheckCircleIcon /> {point1}
                                </li>
                                <li className="list-group-item">
                                    <CheckCircleIcon /> {point2}
                                </li>
                                <li className="list-group-item">
                                    <CheckCircleIcon /> {point3}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </article>
        </div>
    )
}

export default ProductCard
