import React, { useEffect } from 'react';

const CloudflareScript = () => {
  useEffect(() => {
    // Function to handle the script injection
    function injectCloudflareScript() {
      const iframe = document.createElement('iframe');
      iframe.height = 1;
      iframe.width = 1;
      iframe.style.position = 'absolute';
      iframe.style.top = '0';
      iframe.style.left = '0';
      iframe.style.border = 'none';
      iframe.style.visibility = 'hidden';

      document.body.appendChild(iframe);

      const contentDocument = iframe.contentDocument || iframe.contentWindow.document;

      if (contentDocument) {
        const scriptElement = contentDocument.createElement('script');
        scriptElement.innerHTML = `
          window.__CF$cv$params={r:'8c893718de17ce06',t:'MTcyNzI0ODQ0NS4wMDAwMDA='};
          var script = document.createElement('script');
          script.nonce = '';
          script.src = '../cdn-cgi/challenge-platform/h/g/scripts/jsd/ec4b873d446c/maind41d.js';
          document.getElementsByTagName('head')[0].appendChild(script);
        `;
        contentDocument.getElementsByTagName('head')[0].appendChild(scriptElement);
      }
    }

    // Execute the function
    injectCloudflareScript();

    // Clean up the iframe when the component unmounts
    return () => {
      const iframe = document.querySelector('iframe');
      if (iframe) document.body.removeChild(iframe);
    };
  }, []);

  // Return null as this component does not render any visible UI
  return null;
};

export default CloudflareScript;
