import React from 'react'
import ProductCard from '../common/ProductCard'
import { ProductData } from '../Data/ProductData';
function ProductFeature(props) {
    return (
        <section className="section-lg">
            <div className="container">
                <div className={props?.subtitle ? "pbmit-heading-subheading center-align text-center" : undefined}>
                    <h4 className="pbmit-subtitle">{props.subtitle}</h4>
                    <h2 className="pbmit-title">{props.title}</h2>
                </div>
                <div className="row">
                    {ProductData.map((dataPro) => <ProductCard
                        imgurl={dataPro.imgURL}
                        title={dataPro.title}
                        para={dataPro.para}
                        point1={dataPro.point1}
                        point2={dataPro.point2}
                        point3={dataPro.point3}
                    />)}
                </div>
            </div>
        </section>

    )
}

export default ProductFeature
