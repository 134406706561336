import React from 'react'
import { BrowserRouter as Router, Link } from "react-router-dom";
import FooterTop from './FooterTop'
import footerLogo from '../images/GloriousEVFooter.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faYoutube, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';
import Button from './common/Button';
function FooterMian() {
    return (
        <>
        <FooterTop />
            <footer className="footer site-footer">
                <div className="pbmit-footer-widget-area">
                    <div className="container">
                        <div className="row">
                            <div className=" col-sm-12 col-md-6 col-lg-4">
                                <div className="pbmit-footer-widget">
                                    <aside className=" pbmit-footer-news widget">
                                        <div className="textwidget">
                                            <form className>
                                                <div className="mc4wp-form-fields">
                                                    <div className="pbmit-footer-newsletter">
                                                        <i aria-hidden="true" className="pbmit-industrey-icon pbmit-base-icon-mail-box" />
                                                        <h4>Subscribe for the Latest E-Vehicle Updates & Offers!</h4>
                                                        <div className="pbmit-footer-newsletter-form">
                                                            <label>Email address</label>
                                                            <input type="email" name="EMAIL" placeholder="Email address" required />
                                                            <button type="submit" value="Sign up"><span>Subscribe</span></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </aside>
                                </div>
                            </div>
                            <div className=" col-sm-12 col-md-6 col-lg-3">
                                <div className="pbmit-footer-widget">
                                    <aside className="widget">
                                        <h2 className="widget-title">Glorious EV</h2>
                                        <div className="textwidget">
                                            <p>At Glorious EV, we envision a world where transportation is sustainable, affordable, and accessible to all. <br />
                                                <Link className="btn-arrow" to="#"><Button name="Book Now" /> </Link>
                                            </p>
                                        </div>
                                    </aside>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-2">
                                <div className="pbmit-footer-widget ">
                                    <aside className=" widget widget_nav_menu">
                                        <h2 className="widget-title">Company info</h2>
                                        <div className="menu-information-container">
                                            <ul id="menu-information" className="menu">
                                                <li className="menu-item "> <Link to="#"> <KeyboardDoubleArrowRightOutlinedIcon /> About Us</Link> </li>
                                                <li className="menu-item "> <Link to="#"><KeyboardDoubleArrowRightOutlinedIcon />  Our Services</Link> </li>
                                                <li className="menu-item "> <Link to="#"><KeyboardDoubleArrowRightOutlinedIcon />  Features</Link> </li>
                                                <li className="menu-item "> <Link to="#"><KeyboardDoubleArrowRightOutlinedIcon /> Blogs</Link> </li>
                                                <li className="menu-item "> <Link to="#"><KeyboardDoubleArrowRightOutlinedIcon />  Contact Us</Link> </li>
                                            </ul>
                                        </div>
                                    </aside>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-3 col-lg-3">
                                <div className="pbmit-footer-widget">
                                    <aside className="widget">
                                        <h2 className="widget-title">Quick Contact</h2>
                                        <div className="textwidget">
                                            <p>2307 Beverley Rd Brooklyn, New York 11226 United States.</p>
                                            <p>If you have any questions or need help, feel free to contact with our team.</p>
                                            <h3 className="footer-phone">info@gloriousev.com</h3>
                                        </div>
                                    </aside>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pbmit-footer-style-2 pbmit-footer-section pbmit-footer-text-area pbmit-bg-color-transparent">
                    <div className="container">
                        <div className="pbmit-footer-text-inner">
                            <div className="row align-items-center">
                                <div className="col-md-5">
                                    {/* <div className="pbmit-footer-menu-area">
                                        <div className="menu-quick-links-container">
                                            <ul className="pbmit-footer-menu">
                                                <li className="menu-item">
                                                    <Link to="#">Where to Find Us</Link>
                                                </li>
                                                <li className="menu-item">
                                                    <Link to="#">Terms of Payment</Link>
                                                </li>
                                                <li className="menu-item">
                                                    <Link to="#">Stats Element</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div> */}
                                    <div className="pbmit-footer-copyright-text-area"> Copyright © 2024
                                        <Link to="#"> GloriousEV</Link>, All Rights Reserved.
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="pbmit-footer-logo-box">
                                        <div className="pbmit-footer-logo">
                                            <img className="pbmit-footer-logo" src={footerLogo} alt="Grevo Demo1" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className=" pbmit-footer-menu-area pbmit-footer-copyright-text ">
                                        <ul className="pbmit-social-links">
                                            <li className="pbmit-social-li pbmit-social-facebook ">
                                                <Link to="#">
                                                    <span>
                                                        <FontAwesomeIcon icon={faFacebook} />
                                                    </span>
                                                </Link>
                                            </li>
                                            <li className="pbmit-social-li pbmit-social-twitter ">
                                                <Link to="#">
                                                    <span>
                                                        <FontAwesomeIcon icon={faTwitter} />
                                                    </span>
                                                </Link>
                                            </li>
                                            <li className="pbmit-social-li pbmit-social-youtube ">
                                                <Link to="#">
                                                    <span>
                                                        <FontAwesomeIcon icon={faYoutube} />
                                                    </span>
                                                </Link>
                                            </li>
                                            <li className="pbmit-social-li pbmit-social-linkedin ">
                                                <Link to="#">
                                                    <span>
                                                        <FontAwesomeIcon  icon={faLinkedin} />
                                                    </span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        </>
    )
}

export default FooterMian;
