import React from 'react'
import Button from './Button'
import EvStationOutlinedIcon from '@mui/icons-material/EvStationOutlined';
function Price1() {
    return (
        <>
         <div className="pbmit-ptable-col col-sm-12 col-md-6 col-xl-4">
            <div className="pbmit-pricing-table-box">
                <div className="pbmit-icon-wrap">
                    <div className="pbmit-ptable-icon">
                        <div className="pbmit-ptable-icon-wrapper">
                            <EvStationOutlinedIcon className="pbmit-grevo-icon" />
                        </div>
                    </div>
                </div>
                <div className="pbmit-head-wrap">
                    <h3 className="pbminfotech-ptable-heading">Home charging</h3>
                    <div className="pbminfotech-sep" />
                </div>
                {/* <div className="pbminfotech-ptable-price-w">
                    <div className="pbminfotech-ptable-price">240</div>
                    <div className="pbminfotech-ptable-symbol">$</div>
                    <div className="pbminfotech-ptable-frequency">mo</div>
                </div> */}
                <div className="pbmit-ptable-inner">
                    <div className="pbmit-ptable-lines-w">
                        <div className="pbmit-ptable-line">
                            <i className="fa fa-check-circle" /> Plug-and-play home charging solution
                        </div>
                        <div className="pbmit-ptable-line">
                            <i className="fa fa-check-circle" /> Sleek, compact design for easy installation
                        </div>
                        <div className="pbmit-ptable-line">
                            <i className="fa fa-check-circle" /> Smart energy-saving technology
                        </div>
                        <div className="pbmit-ptable-line">
                            <i className="fa fa-check-circle" /> 24/7 customer support for charging assistance
                        </div>
                    </div>
                    {/* <div className="pbmit-ptable-btn">
                        <a href="our-plans.html"> <Button name='View More' color="#111" /> </a>
                    </div> */}
                </div>
            </div>
            </div>
        </>
    )
}

export default Price1
