import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '@fortawesome/fontawesome-free/css/all.min.css';
import Navbar from './Component/Navbar';
import CloudflareScript from './Component/CloudflareScript';
import '../src/assets/css/style.css';
import '../src/assets/css/shortCode.css';
import '../src/assets/css/base.css';
import '../src/assets/css/res6.css';
import '../src/assets/css/responsive.css';
import '../src/assets/css/pageResponsive.css';
import FooterMian from './Component/FooterMian';
import Index from './Component/mai_content/Index';
import AboutUsPage from './Component/AboutUs/AboutUs';
import Services from './Component/Services/Services';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import { useEffect, useLocation } from "react";
import ScrollToTop from './Component/common/ScrollToTop';
import Features from './Component/Features/Features';
import ContactUs from './Component/Contact/ContactUs';



function App() {
  return (
    <div className="page-wrapper">
      <Router>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/aboutUsPage" element={<AboutUsPage />} />
          <Route path="/services" element={<Services />} />
          <Route path="/features" element={<Features />} />
          <Route path="/contactUs" element={<ContactUs />} />
        </Routes>
        <FooterMian />
        <CloudflareScript />
      </Router>
    </div>
  );
}

export default App;
