import React from 'react'
import AboutUs from './AboutUsSec'
import Counter from './Counter'
import VideoSec from './VideoSec'
import WhatWeDo from './WhatWeDo'
import PriceSec from './PriceSec'
import BookingSection from '../common/BookingSection'
import Blog from './Blog'
import ProductFeature from './ProductFeature'
import ServicesSec from './ServicesSec'
import MainSlider from '../MainSlider';
import Gallery from './Gallery'

function Index() {
    return (
        <>
         <MainSlider />
            <div className="page-content">
                <AboutUs />
                <ProductFeature subtitle="Our Product" title="Our Product & Features" />
                <Counter />
                <VideoSec />
                <ServicesSec />
                <WhatWeDo />
                <PriceSec />
                <BookingSection />
                <Blog />
                <Gallery />
            </div>
        </>
    )
}

export default Index
