import Product1 from '../../images/services/Support.jpg'
import Product2 from '../../images/services/service-02.jpg'
import Product3 from '../../images/services/Finance.jpg'
import Product4 from '../../images/services/warranty.jpg'
import Product5 from '../../images/services/E-Bike1.jpg'
import Product6 from '../../images/services/E-Scooterq1.jpg'
export const ServiceData = [
   
    {
        title: "Maintenance and Support",
        imgURL: Product1,
        point1: "Regular maintenance services to keep your vehicle running smoothly.", 
        point2: "24/7 customer support for any questions or issues."
    },
    {
        title: "Charging Stations",
        imgURL: Product2,
        point1: "A network of fast-charging stations in convenient locations.", 
        point2: "Mobile app integration to locate the nearest charging point."
    },
    {
        title: "Financing Options",
        imgURL: Product3,
        point1: "Flexible payment plans and financing options to suit your budget.", 
        point2: "Smart Tech Integration" 
    },
    {
        title: "Extended Warranty Plans",
        imgURL: Product4,
        point1: "Comprehensive coverage for key components.", 
        point2: " Flexible plans to suit your needs."
    },
    {
        title: "On-Demand Test Rides",
        imgURL: Product5,
        point1: "Experience our e-vehicles before purchasing.", 
        point2: " Convenient scheduling at your location."
    },
    {
        title: "Trade-In Offers",
        imgURL: Product6,
        point1: "Competitive exchange offers for your current vehicle.", 
        point2: "Seamless upgrade to eco-friendly transportation." 
    }
    ]