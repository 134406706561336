import React from 'react'
import BackImg from '../../images/icon/charging-station.png';
import Button from './Button';
function BookingSection() {
    return (
        <section className="home-2-section-appointment">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 pbmit-bg-color-global home-2-section-appointment-left">
                        <div className="row align-items-center">
                            <div className="col-lg-8 col-md-7">
                                <div className="pbmit-ihbox pbmit-ihbox-style-3">
                                    <div className="pbmit-ihbox-box">
                                        <div className="pbmit-ihbox-icon">
                                            <div className="pbmit-ihbox-icon-wrapper">
                                                <img src={BackImg} className='backimg' />
                                            </div>
                                        </div>
                                        <div className="pbmit-ihbox-contents">
                                            <h2 className="pbmit-element-title">Built for Your Roads</h2>
                                            <div className="pbmit-heading-desc text-white">Navigate the city with ease and style on Glorious EV scooters, offering unmatched comfort, speed, and sustainability for everyday journeys.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-5">
                                <div className="pbmit-bg-color-global">
                                    <a href="contact-us.html" className="pbmit-btn pbmit-btn-white pbmit-btn-hover-secondary">
                                    <Button name='Book Now' /> 
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="home-2-section-appointment-img" />
                    </div>
                </div>
            </div>
        </section>

    )
}

export default BookingSection
