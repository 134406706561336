import React from 'react'
import GalleryCard from '../common/GalleryCard'
import { GalleryData } from '../Data/GalleryData';
function Gallery() {
    return (
        <>
            <section className="section-lg">
                <div className="container">
                    <div className="pbmit-heading-subheading center-align text-center">
                        <h4 className="pbmit-subtitle">Our Gallery</h4>
                        <h2 className="pbmit-title">Our Product Gallery</h2>
                    </div>

                    <div className="row">
                    {GalleryData.map((gal) => <GalleryCard
                        imgurl={gal.imgURL}
                        title={gal.title}
                        subtitle={gal.subtitle}
                    />)}
                    </div>
                </div>
            </section>

        </>
    )
}

export default Gallery

