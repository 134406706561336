import React from 'react'
import { BrowserRouter as Router, Link } from "react-router-dom";
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
function FooterTop() {
  return (
   <>
   <section className="footer-wrap pbmit-footer-big-area">
  <div className="container">
    <div className="row">
      <div className="col-sm-12 d-flex justify-content-between">
        <div className="pbmit-footer-contact-info pbmit-footer-contact-info-1">
          <span className="pbmit-header-box-icon">
            <SupportAgentOutlinedIcon className="pbmit-grevo-icon" />
          </span>						
          <div className="pbmit-footer-contact-info-wrap">
            <span className="pbmit-label">Support</span>
            <span className="pbmit-desc">support@gloriousev.com</span>
          </div>
        </div>
        <div className="pbmit-footer-contact-info pbmit-footer-contact-info-2">
          <span className="pbmit-header-box-icon">
            <MailOutlineOutlinedIcon className="pbmit-grevo-icon" />
          </span>						
          <div className="pbmit-footer-contact-info-wrap">
            <span className="pbmit-label">E-mail Address</span>
            <span className="pbmit-desc"><Link to="#" className="__cf_email__" data-cfemail="#">info@gloriousev.com</Link></span>
          </div>
        </div>
        <div className="pbmit-footer-contact-info pbmit-footer-contact-info-3">
          <span className="pbmit-header-box-icon">
            <RoomOutlinedIcon className="pbmit-grevo-icon" />
          </span>						
          <div className="pbmit-footer-contact-info-wrap">
            <span className="pbmit-label">Our Location</span>
            <span className="pbmit-desc">Ranchi, Dhoni, Form House Opposite Jharkhand
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

   
   </>
  )
}

export default FooterTop
